.contact-list-container {
  display: inline-block;
  border: 1px solid #d5dbdb;
  border-radius: 16px;
  height: 36px;
}

.contact-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  height: 100%;
}

.contact-name-tag {
  display: flex;
  align-items: center;
  height: 20px;
}

.circular-avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  margin-right: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.team-tab-grid {
  // This ensures the grid takes full width
  width: 100%;
}

.video-container-wrapper,
.welcome-content-wrapper {
  height: 410px; // Adjust this value as needed to match your desired total height
  display: flex;
  flex-direction: column;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  flex-grow: 0;
  flex-shrink: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.team-overview-container {
  height: 120px; // Fixed height
  overflow-y: auto;
  margin-top: 2rem;
}

.welcome-content-wrapper {
  overflow-y: auto;
}

.welcome-content-item {
  margin-bottom: 1rem;
}

.welcome-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.welcome-content-title {
  flex: 1;
}

.welcome-content-contact {
  flex-shrink: 0;
}

.welcome-modal-content {
  max-height: calc(100vh - 300px); /* Adjust 200px as needed to account for header and footer */
  overflow-y: auto;
  padding-right: 20px; /* To prevent content from touching the scrollbar */
}

/* Optional: Style the scrollbar */
.welcome-modal-content::-webkit-scrollbar {
  width: 8px;
}

.welcome-modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.welcome-modal-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.welcome-modal-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.welcome-content-modal-table-container {
  max-height: 400px; /* Adjust this value as needed */
  overflow-y: auto;
}

.description-cell {
  max-height: 100px; /* Adjust this value as needed */
  overflow-y: auto;
  white-space: normal;
  word-break: break-word;
}

/* Optional: Style the scrollbar for the description cell */
.description-cell::-webkit-scrollbar {
  width: 6px;
}

.description-cell::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.description-cell::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.description-cell::-webkit-scrollbar-thumb:hover {
  background: #555;
}
